<template>
    <v-container fluid>
        <v-tabs v-model="tab" class="mt-4">
            <v-tab key="simpro">Simpro</v-tab>
            <v-tab key="medicamento">Medicamento </v-tab>
            <!-- <v-tab key="med_res_hosp">Medicamento Rest.Hospitalar</v-tab> -->
            <v-tab key="solucoes">Soluções</v-tab>
            <v-tab key="dieta">Dietas</v-tab>
            <v-tab key="tabela">Tabela Própria</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item key="simpro">
                <Simpro />
            </v-tab-item>
            <v-tab-item key="medicamento">
                <Medicamento />
            </v-tab-item>
            <!-- <v-tab-item key="med_res_hosp">
                <MedRestritoHospitalar />
            </v-tab-item> -->
            <v-tab-item key="solucoes">
                <Solucoes />
            </v-tab-item>
            <v-tab-item key="dieta">
                <Dietas />
            </v-tab-item>
            <v-tab-item key="tabela">
                <TabelaPropria />
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import '../../../../assets/css/styles.css'
import Simpro from "./simpro.vue";
// import MedRestritoHospitalar from "./med_restrito_hospiatalar.vue";
import Medicamento from "./medicamento.vue";
import Solucoes from "./solucao.vue";
import Dietas from "./dieta.vue";
import TabelaPropria from "./tabela_propria/index.vue";

export default {
    name: 'Tabelas',
    components: {
        Simpro,
        // MedRestritoHospitalar,
        Medicamento,
        Solucoes,
        Dietas,
        TabelaPropria,
    },
    data() {
        return {
            tab: 0,  // Define a primeira aba como ativa
        };
    }
};
</script>

<style scoped>
.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h1 {
  margin: 0;
  flex-shrink: 0;
}
</style>

