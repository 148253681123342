<template>
  <div>
    <v-data-table :loading="isLoading('get:tabelapropria')"  :items="filteredData" :search="search" :headers="headers" :items-per-page="-1" height="900px" fixed-header >
      
      <template v-slot:top> 
        <v-row class="mt-4" dense>
          <v-col >
            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
          </v-col>
          <v-col class="text-end">
              <v-badge left bordered overlap :content="activeFilters.length">
                  <v-btn class="mr-4 elevation-0" text v-on:click="showFilters = !showFilters">
                  <v-icon left>
                      mdi-filter
                  </v-icon>
                  Filtros
                  </v-btn>
              </v-badge>
              <v-btn class="mr-4 elevation-0" text @click="collapseAll"> Recolher Linhas </v-btn>
              <v-btn class="mr-4 elevation-0" text @click="atualizar"> <v-icon left>mdi-reload</v-icon> Atualizar </v-btn>
              <v-btn color="success" class="elevation-0" @click="dialog.create = true"> <v-icon left> mdi-plus </v-icon>  Novo </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card outlined v-show="showFilters" dense>
            <v-row dense class="py-2 px-4 align-center">
              <!-- Filtro à esquerda -->
              <v-col cols="2">
                <v-select hide-details :items="statusOptions" label="Status" item-text="label" item-value="value"
                    v-model="filters.is_active.value" outlined dense />
              </v-col>
              
              <!-- Botão "Aplicar Filtros" à direita -->
              <v-col class="text-right">
                <v-btn color="success" elevation="0" @click="applyFilters">
                  Aplicar filtros
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>

      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn icon @click="onSelectRow(item, 'update')">
          <v-icon color="green">
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
          <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
        </v-chip>
      </template>
    <template v-slot:[`item.preco`]="{ item }">
        <span>{{ formatCurrency(item.preco) }}</span>
    </template>
    <template v-slot:[`item.data_inicio`]="{ item }">
        <span>{{ formatDate(item.data_inicio) }}</span>
    </template>
    <template v-slot:[`item.data_fim`]="{ item }">
        <span>{{ formatDate(item.data_fim) }}</span>
    </template>
    <template v-slot:[`item.tabela`]="{ item }">
        <span>{{item.tabela.tabela }} - {{item.tabela.nome}}</span>
    </template>
    <template v-slot:[`item.despesa`]="{ item }">
        <span>{{item.despesa.despesa }} - {{item.despesa.nome}}</span>
    </template>
    <template v-slot:[`item.tipoguia`]="{ item }">
        <span>{{item.tipoguia.tipoguia }} - {{item.tipoguia.nome}}</span>
    </template>
    <template v-slot:[`item.resultado`]="{ item }">
      <template v-if="getResultadoColor(item) !== 'grey'">
        <v-icon :color="getResultadoColor(item)" size="22">mdi-currency-brl</v-icon>
      </template>
    </template>

    </v-data-table>
    <v-dialog v-model="dialog.create" v-if="dialog.create" max-width="90%">
      <v-card>
        <v-card-title class="blod_color">
          Novo Produto:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <Nova :currentItem="newItem" :loading="isLoading('post:tabelapropria')" :onSubmit="createMatMed" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.update" v-if="dialog.update" max-width="90%">
      <v-card>
        <v-card-title class="blod_color">
          Editar Medicamento: Id: {{ selectedRow.id}} - {{selectedRow.produto.nome_substancia}}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <Editar :currentItem="selectedRow" :loading="isLoading('put:tabelapropria')" :onSubmit="updateEquipamento" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../../../../http';
import UtilsFunc from '../../../../../service/utilsFunc';
import './../../../../../assets/css/styles.css';
import moment from 'moment';
import Editar from "./editar.vue";
import Nova from "./nova.vue";

export default {
    name: "TabelaPropria",
    data() {
        return UtilsFunc.withCRUDUtils({
        newItem: { ativo:1 },
        headers: [
            { text: "Editar", value: "edit", width: 50 },
            { text: "Id", value: "id", width: 30 },
            { text: "Operadora", value: "operadora.nome" },
            { text: "Item", value: "produto.nome_substancia" },
            { text: "Código", value: "codigo" },
            { text: "Valor", value: "preco" },
            { text: "Cód. Tabela", value: "tabela" },
            { text: "Cód. Despesa", value: "despesa" },
            { text: "Tipo Guia", value: "tipoguia" },
            { text: "Iníco Vigência", value: "data_inicio" },
            { text: "Fim Vigência", value: "data_fim" },
            { text: "R$", value: "resultado"},
            { text: "Status", value: "ativo" },
        ],
        defaultSort: {
            name: 'ativo',
            isDesc: true
        },
        statusOptions: [
            { label: "Ativo", value: true },
            { label: "Inativo", value: false }
        ],
        tabelapropria: [],
        showFilters: false,
        activeFilters: [],
        filters: {
            is_active: { value: true, compareType: 'equal', prop: 'ativo' }
        },
        expanded: [],
        });
    },
    components: { Editar, Nova },
    methods: {
        async getProdutos() {
          const LOADING_NAME = "get:tabelapropria";
          this.setLoading(LOADING_NAME);
          try {
              const response = await api.get("/matmed/tabela_propria/");
              this.tabelapropria = response.data;
              // console.log(this.tabelapropria)
              this.applyFilters(); // Aplica filtros após o carregamento
          }
          catch (error) {
              this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
          }
          finally {
              this.setLoading(LOADING_NAME, true);
          }
        // console.log(this.tabelapropria)
        },
        async createMatMed(fields) {
          const LOADING_NAME = "post:tabelapropria";
          this.setLoading(LOADING_NAME);
          try {
              // console.log(fields)
              await api.post("/matmed/tabela_propria_criar/", fields);
              await this.getProdutos()
              this.dialog.create = false
              this.$toast.success('Produto criado com sucesso!')
          }
          catch (error) {
              this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
          }
          finally {
              this.setLoading(LOADING_NAME, true);
          }
        },
        async updateEquipamento(fields) {
          const LOADING_NAME = "put:tabelapropria";
          this.setLoading(LOADING_NAME);
          try {
              await api.put(`/matmed/tabela_propria_editar/${fields.id}/`, fields);
              await this.getProdutos()
              this.dialog.update = false
              this.$toast.success('Produto atualizado com sucesso!')
          }
          catch (error) {
              this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
          }
          finally {
              this.setLoading(LOADING_NAME, true);
          }
        },
        async applyFilters() {
          this.search = "";
          this.activeFilters = Object.entries(this.filters).reduce((acc, [key, filter]) => {
              if (filter.value !== undefined && filter.value !== null) {
              acc[key] = { ...filter };
              }
              return acc;
          }, {});

          this.$forceUpdate(); // Força a atualização do Vue para refletir os filtros aplicados
          },

          clearFilters() {
          this.filters = {
              is_active: { value: true, compareType: "equal", prop: "ativo" }
          };
          this.activeFilters = {}; // Garante que nenhum outro filtro esteja aplicado
          this.$forceUpdate(); // Força atualização da tela para refletir a mudança
        },
        atualizar() {
          this.tabelapropria = [];
          this.search = "";
          this.showFilters = false;
          this.clearFilters();
          this.getProdutos();
          this.expanded = []
        },
        inicializar(){
          this.getProdutos();
        },
        collapseAll() {
          this.expanded = [];
        },
        formatDate(date) {
          return date ? moment(date).format("DD/MM/YYYY") : "";
        },

        formatCurrency(value) {
          return value ? new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(value) : "";
        },
        getResultadoColor(item) {
          // Convertendo custo e percentualCusto para números
          const custo = parseFloat(item?.produto.custo) || 0;
          const percentualCusto = parseFloat(item?.produto.custo_empresa) || 0;
          
          // Calculando o custo final considerando o percentual
          const resultadoCusto = custo * (1 + (percentualCusto / 100));
          // console.log(resultadoCusto)
          // Calculando o preço unitário correto
          const precoFabUnidade = item?.preco ? parseFloat(item.preco)  : 0;

          // console.log("Custo:", resultadoCusto, "Preço Unitário FAB:", precoFabUnidade);

          // Se custo ou preço unitário forem 0 ou inexistentes, retorna cinza
          if (resultadoCusto === 0 || precoFabUnidade === 0) {
              return "grey";
          }

          // Se o custo final for menor que o preço unitário, retorna verde (lucro), senão vermelho (prejuízo)
          return resultadoCusto < precoFabUnidade ? "green" : "red";
      },
    },
    computed: {
        filteredData() {
        let data = [...this.tabelapropria];

        // Aplica os filtros SOMENTE se o botão "Aplicar filtros" foi pressionado
        if (this.activeFilters.is_active?.value !== undefined) {
            data = data.filter(item => item.ativo === this.activeFilters.is_active.value);
        }

        if (this.search) {
            const searchTerm = this.search.toLowerCase().trim();
            data = data.filter((item) => {
            const nome = item.operadora.nome?.toLowerCase() || "";
            const produto = item.produto.nome_substancia.toLowerCase() || "";
            // const tipologia = item.tipologia?.nome?.toLowerCase() || "";

            return (
                nome.includes(searchTerm) ||
                produto.includes(searchTerm) 
                // tipologia.includes(searchTerm)
            );
            });
        }

        return data.sort((a, b) => {
            if (a.ativo === b.ativo) {
            return a.produto.nome_substancia.localeCompare(b.nome_substancia);
            }
            return b.ativo - a.ativo;
        });
        },
    },
    mounted() {
        this.inicializar();
    },

}
</script>
