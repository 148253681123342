<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
<!-- <prev> {{currentItem}}</prev> -->
    <v-row dense>
      <v-col cols="3">
        <v-text-field dense label="Operadora" v-model="currentItem.operadora.nome" outlined  :loading="isLoadingGeral" readonly/>
      </v-col>
      <v-col cols="7">
        <v-text-field dense label="Produto" v-model="currentItem.produto.nome_substancia" outlined  :loading="isLoadingGeral" readonly/>
      </v-col>
      <v-col cols="2">
        <v-text-field dense label="Código" v-model="currentItem.codigo" outlined  :loading="isLoadingGeral" type="number"/>
         <span style="position: absolute; top: 28%; right: 80px; transform: translateY(20%);" >Código com {{ currentItem.codigo ? currentItem.codigo.toString().length : 0 }} dígitos</span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <v-text-field dense label="Precificação Sugerida" :value="precoPrecificacaoDinamico" outlined readonly disabled />
      </v-col>
      <v-col >
        <vuetify-money dense label="Preço " v-model="currentItem.preco" outlined  :options="options" :loading="isLoadingGeral" :rules="requiredField"/>
      </v-col>
        <v-col >
            <v-text-field dense label="Data Início" v-model="currentItem.data_inicio" outlined clearable type="date" :rules="requiredField" />
        </v-col>
        <v-col >
            <v-text-field dense label="Hora Fim" v-model="currentItem.data_fim"  outlined clearable type="date"  />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col >
            <v-autocomplete dense label="Tabela" v-model="currentItem.tabela.id" :items="tabela" :item-text="tabelaText" item-value="id" outlined  :loading="isLoadingGeral" :rules="requiredField" />
        </v-col>
        <v-col >
            <v-autocomplete dense label="Código Despesa" v-model="currentItem.despesa.id" :items="despesa" :item-text="despesaText" item-value="id" outlined  :loading="isLoadingGeral" :rules="requiredField" />
        </v-col>
        <v-col >
            <v-autocomplete dense label="Tipo Guia" v-model="currentItem.tipoguia.id" :items="tipoguia" :item-text="tipoguiaText" item-value="id" outlined  :loading="isLoadingGeral" :rules="requiredField" />
        </v-col>
    </v-row>
    <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>
 
<script>
import moment from 'moment';
import api from '../../../../../http';
import UtilsFunc from '../../../../../service/utilsFunc';
import ConfirmButton from '../../../../ConfirmButton.vue';


export default {
  name: "EditarMaterial",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      requiredField: [e => (e !== null && e !== undefined && e !== '' ? true : 'Obrigatório')],
      isLoadingGeral: false,
      tabela: [],
      despesa: [],
      tipoguia: [],
      classificacao: [],
      apresentacao_prescricao: [],
      endereco: [],
      statusOptions: [
        { label: "Sim", value: true },
        { label: "Não", value: false }
      ],
       options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2,
      },
    })
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      const fields = {
        id: this.currentItem.id,
        tabela: this.currentItem.tabela ? this.currentItem.tabela.id || this.currentItem.tabela : null,
        despesa: this.currentItem.despesa ? this.currentItem.despesa.id || this.currentItem.despesa : null,
        tipoguia: this.currentItem.tipoguia ? this.currentItem.tipoguia.id || this.currentItem.tipoguia : null,
        codigo: this.currentItem.codigo,
        preco: this.currentItem.preco,
        data_inicio: this.currentItem.data_inicio,
        data_fim: this.currentItem.data_fim,
        ativo: this.currentItem.ativo
      };

      
      this.onSubmit(fields);
    },

     async getTabela() {
        this.isLoadingGeral = true
      try {
        const { data } = await api.get('operadoras/tabelas/')
        this.tabela = data.filter(tabela => tabela.ativo)
        // console.log('tabelas --> ',this.tableValues)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getDespesa() {
         this.isLoadingGeral = true
      try {
        const { data } = await api.get('operadoras/despesas/')
        this.despesa = data.filter(despesa => despesa.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getTipoGuia() {
         this.isLoadingGeral = true
      try {
        const { data } = await api.get('operadoras/tipoguia/')
        this.tipoguia = data.filter(tipoguia => tipoguia.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingGeral = false
      } 
    },
    getPrecoPrecificacao() {
      if (!this.currentItem || !this.currentItem.produto) {
        return "R$ 0,00";
      }

      // 🔹 Ajustado para buscar diretamente dentro do `currentItem.produto`
      const custo = parseFloat(this.currentItem.produto.custo) || 0;
      const percentualCusto = parseFloat(this.currentItem.produto.custo_empresa) || 0;

      const resultadoCusto = custo * (1 + percentualCusto / 100);

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(resultadoCusto);
    },


  },
  computed:{
    tabelaText() {
      return (item) => {
          return `${item.tabela} - ${item.nome}`;
      }
    },
    despesaText() {
      return (item) => {
          return `${item.despesa} - ${item.nome}`;
      }
    },
    tipoguiaText() {
      return (item) => {
          return `${item.tipoguia} - ${item.nome}`;
      }
    },
    precoPrecificacaoDinamico() {  // 🔹 Aqui está o computed que estava faltando
      return this.getPrecoPrecificacao();
    }
    
  },
  watch: {
    // Se o usuário marcar "Ativo", preenche a `data_fim` com a data atual
      'currentItem.ativo': function(newValue) {
      if (!newValue) {  // Se o usuário marcar como "Inativo"
        if (!this.currentItem.data_fim) {  // Se a data_fim ainda não estiver preenchida
          this.currentItem.data_fim = moment().format('YYYY-MM-DD');
        }
      }
    },
     "currentItem.produto": {
      handler(newVal) {
        if (newVal) {
          this.precoPrecificacaoDinamico = this.getPrecoPrecificacao(); // ❌ ERRO: Computed não pode ser reatribuído
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getDespesa()
    this.getTipoGuia()
    this.getTabela()
    if (this.currentItem.padrao === undefined || this.currentItem.padrao === null) {
      this.currentItem.padrao = false;
    }
    this.currentItem.ativo = Number(this.currentItem.ativo)
  },
 
}
</script>
