<template>
  <v-container fluid class="gestao-documentos">
   <v-card class="pa-4">
      <v-card-title>Importação de Arquivo Medicamento</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row dense align="center">
            <!-- Campo para selecionar a versão -->
            <v-col cols="2">
              <v-text-field  
                v-model="versao"  
                label="Versão"  
                outlined  
                dense  
                type="number"  
                required  
              ></v-text-field>
            </v-col>

            <!-- Campo para selecionar o arquivo -->
            <v-col cols="6">
              <v-file-input  
                v-model="file"  
                label="Selecione o arquivo .TXT de Medicamdentos."  
                outlined  
                dense  
                accept=".txt"  
                required  
              ></v-file-input>
            </v-col>

            <!-- Botão de Importação -->
            <v-col cols="2" class="d-flex align-center justify-end">
              <v-btn :loading="loading" color="primary" @click="uploadFile">
                Importar
              </v-btn>
            </v-col>
            <!-- Mensagem de instruções -->
          <v-col cols="12">
            <v-alert dense outlined type="info" class="text-caption">
              O arquivo a ser importado deve estar no formato <strong>.TXT</strong> e conter as seguintes parâmetros selecionados na Revista: <br>
              <strong>MEDICAMENTO, MA, 2, DELIMITADO, CÓDIGO, DESCRIÇÃO, PMC, PFAB, TISS, TUSS, EAN, ANVISA e GENÉRICO</strong>.
              Certifique-se de que o arquivo segue o modelo esperado para evitar erros na importação.
            </v-alert>
          </v-col>
          </v-row>

          <!-- Mensagem de erro caso a validação falhe -->
          <v-alert v-if="validationMessage" type="error" class="mt-4">
            {{ validationMessage }}
          </v-alert>
          <v-alert v-else-if="message" type="success" class="mt-4">
            {{ message }}
          </v-alert>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Tabela para mostrar os dados importados -->
    <v-card class="mt-4">
      <v-card-title class="d-flex justify-space-between align-center">
        <span>Lista Ativa de Produtos</span>
      </v-card-title>

      <v-card-text>
        <v-row align="center" class="mb-5">
          <!-- Campo de Pesquisa -->
          <v-col cols="8">
            <v-text-field  
              v-model="search"  
              label="Pesquisar por Descrição, Cod. Brasindice ou Cod. TUSS"  
              outlined  
              dense  
              clearable  
              prepend-inner-icon="mdi-magnify"  
            ></v-text-field>
          </v-col>

          <!-- Botão de Atualizar -->
          <v-col cols="4" class="text-right">
            <v-btn color="blue" small @click="atualizar" :loading="loadingAtualizar">
              🔄 Atualizar Lista
            </v-btn>
          </v-col>
        </v-row>
        
        <!-- DataTable -->
        <v-data-table  
          :headers="headers"  
          :items="filteredItems"  
          dense  
          no-data-text="Nenhum dado importado ainda."  
          :loading="loadingAtualizar"
          :items-per-page="-1"
          :height="800"
          fixed-header  
       
          class="custom-table"
          virtual-scroll
        >
          <!-- Formatação da Data -->
          <template v-slot:[`item.data_importacao`]="{ item }">
            <span>{{ formatDate(item.data_importacao) }}</span>
          </template>

          <!-- Formatação do Código Brasíndice (10 dígitos) -->
          <template v-slot:[`item.cod_brasindice`]="{ item }">
            <span>{{ formatCode(item.cod_brasindice, 10) }}</span>
          </template>
          <template v-slot:[`item.restrito_hospitalar`]="{ item }">
            <span>{{ (item.restrito_hospitalar ? "Sim" : "Não") }}</span>
          </template>
          <template v-slot:[`item.generico`]="{ item }">
            <span>{{ (item.generico ? "Sim" : "Não") }}</span>
          </template>

          <!-- Formatação do Código TUSS (8 dígitos) -->
          <template v-slot:[`item.cod_TUSS`]="{ item }">
            <span>{{item.cod_TUSS ?  formatCode(item.cod_TUSS, 8) : ""}}</span>
          </template>

          <!-- Formatação do Registro ANVISA (13 dígitos) -->
          <template v-slot:[`item.registro_anvisa`]="{ item }">
            <span>{{ formatCode(item.registro_anvisa, 13) }}</span>
          </template>
           <template v-slot:[`item.descricao`]="{ item }">
            <span>{{ item.descricao }} - {{item.complemento_descricao}}</span>
          </template>

          <!-- Formatação do Preço -->
          <template v-slot:[`item.preco_fab_unidade`]="{ item }">
            <span>{{ formatCurrency(item.preco_fab_unidade) }}</span>
          </template>
          <template v-slot:[`item.preco_fab_embalagem`]="{ item }">
            <span>{{ formatCurrency(item.preco_fab_embalagem) }}</span>
          </template>
          <template v-slot:[`item.preco_pmc_unidade`]="{ item }">
            <span>{{ formatCurrency(item.preco_pmc_unidade) }}</span>
          </template>
          <template v-slot:[`item.preco_pmc_embalagem`]="{ item }">
            <span>{{ formatCurrency(item.preco_pmc_embalagem) }}</span>
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
              <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import '../../../../assets/css/styles.css'
import api from '../../../../http';
import moment from "moment"; // 📌 Importação do Moment.js para formatação de datas

export default {
    name: 'Medicamento',
    components: {},
    data() {
      return {
        loadingAtualizar: false, // Indicador de carregamento ao atualizar
        versao: "",
        file: null,
        loading: false,
        message: "",
        alertType: "success",
        validationMessage: "",
        importedData: [], // Armazena os dados do banco
        search: "", // Campo de pesquisa
        headers: [
          { text: "ID", value: "id" },
          { text: "Versão", value: "versao" },
          { text: "Descrição", value: "descricao",width: 400  },
          { text: "Fabrica", value: "fabrica" },
          { text: "Restrito Hospitalar", value: "restrito_hospitalar" },
          { text: "Cod. Brasindice", value: "cod_brasindice" },
          { text: "Cod. TUSS", value: "cod_TUSS" },
          { text: "Preço Embalagem 'PMC'", value: "preco_pmc_embalagem" },
          { text: "Preço Embalagem 'PFB'", value: "preco_fab_embalagem" },
          { text: "Preço UND 'PMC'", value: "preco_pmc_unidade" },
          { text: "Preço UND 'PFB'", value: "preco_fab_unidade" },
          { text: "Qtd. Embalagem", value: "qtd_embalagem" },
          { text: "Genérico", value: "generico" },
          { text: "Anvisa", value: "registro_anvisa" },
          { text: "Data de Importação", value: "data_importacao" },
          { text: "Status", value: "ativo" },
        ],
      };
    },
    computed: {
      // 🔍 Filtra os dados conforme a pesquisa
      filteredItems() {
        if (!this.search) return this.importedData;
        const searchLower = this.search.toLowerCase();
        return this.importedData.filter(item => 
          item.descricao?.toLowerCase().includes(searchLower) ||
          String(item.cod_brasindice).includes(this.search) ||
          String(item.cod_TUSS).includes(this.search)
        );
      }
    },
    methods: {
      // async validateAndUploadFile() {
      //    this.message= ""
      //   if (!this.file || !this.versao) {
      //     this.validationMessage = "Por favor, selecione um arquivo e informe a versão.";
      //     return;
      //   }

      //   try {
      //     const text = await this.readFileContent(this.file);
      //     const lines = text.split("\n").slice(0, 5);

      //     const containsRestritoHosp = lines.some(line => line.includes("(Restrito Hosp.)"));

      //     if (containsRestritoHosp) {
      //       this.validationMessage = "O arquivo selecionado contém Medicamento '(Restrito Hosp.)'";
      //       this.file = null
      //       this.versao = null
      //       return;
      //     }
      //     this.message= "",
      //     this.validationMessage = null
      //     // Se passou na validação, chama a importação
      //     this.uploadFile();
      //   } catch (error) {
      //     this.validationMessage = "Erro ao processar o arquivo.";
      //   }
      // },

      // readFileContent(file) {
      //   return new Promise((resolve, reject) => {
      //     const reader = new FileReader();
      //     reader.onload = event => resolve(event.target.result);
      //     reader.onerror = error => reject(error);
      //     reader.readAsText(file, "ISO-8859-1"); // Suporte para acentos
      //   });
      // },

      async uploadFile() {
        if (!this.file || !this.versao) {
          this.validationMessage = "Por favor, selecione um arquivo e informe a versão.";
          this.alertType = "error";
          return;
        }

        this.loading = true;
        this.message = "";


        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("versao", this.versao);
        // formData.append("restrito_hospitalar", false);

        try {
          const response = await api.post("/matmed/brasindice/importar/", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          this.message = response.data.message || "Importação realizada com sucesso!";
          this.alertType = "success";

          this.fetchImportedData();
        } catch (error) {
          this.message = error.response?.data?.error || "Erro ao importar o arquivo.";
          this.alertType = "error";
        } finally {
          this.versao = null,
          this.file = null,
          this.loading = false;
        }
      },

      padLeft(value, length) {
        return String(value).padStart(length, "0");
      },

      formatDate(date) {
        return date ? moment(date).format("DD/MM/YYYY") : "-";
      },

      formatCurrency(value) {
        return value ? new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(value) : "R$ 0,00";
      },
     // 📌 Formata códigos para manter zeros à esquerda
      formatCode(value, length) {
        if (!value) return "".padStart(length, "0");
        return String(value).padStart(length, "0");
      },
      async fetchImportedData() {
        this.loadingAtualizar = true;
        this.importedData = [];
        try {
          const response = await api.get("/matmed/brasindice/");
          this.importedData = response.data.filter(item => !item.solucoes && !item.dieta);
        } catch (error) {
          this.message = "Erro ao carregar os dados importados.";
          this.alertType = "error";
        } finally {
          this.loadingAtualizar = false;
        }
      },
      atualizar() {
        this.fetchImportedData();
      },
    },
    mounted() {
      this.fetchImportedData();
    },
}
</script>
