<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col cols="3">
        <v-autocomplete dense label="Operadora" v-model="currentItem.operadora" :items="operadora" item-text="nome" item-value="id" outlined  :loading="isLoadingOperadora" :rules="requiredField" />
      </v-col>
      <v-col cols="7" >
        <v-autocomplete dense label="Produto" v-model="currentItem.produto" :items="produto" item-text="nome" item-value="id" outlined  :loading="isLoadingProduto" :rules="requiredField" />
      </v-col>
      <v-col cols="2">
        <v-text-field dense label="Código" v-model="currentItem.codigo" outlined   type="number"/>
         <span style="position: absolute; top: 27%; right: 80px; transform: translateY(20%);" >Código com {{ currentItem.codigo ? currentItem.codigo.toString().length : 0 }} dígitos</span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <v-text-field dense label="Precificação Sugerida" :value="precoPrecificacaoDinamico" outlined readonly disabled />
      </v-col>
      <v-col >
        <vuetify-money dense label="Preço " v-model="currentItem.preco" outlined  :options="options"  :rules="requiredField"/>
      </v-col>
      <v-col >
          <v-text-field dense label="Data Início" v-model="currentItem.data_inicio" outlined clearable type="date" :rules="requiredField" />
      </v-col>
      <v-col >
          <v-text-field dense label="Hora Fim" v-model="currentItem.data_fim"  outlined clearable type="date"  />
      </v-col>
      </v-row>
      <v-row dense>
        <v-col >
            <v-autocomplete dense label="Tabela" v-model="currentItem.tabela" :items="tabela" :item-text="tabelaText" item-value="id" outlined  :loading="isLoadingGeral" :rules="requiredField" />
        </v-col>
        <v-col >
            <v-autocomplete dense label="Código Despesa" v-model="currentItem.despesa" :items="despesa" :item-text="despesaText" item-value="id" outlined  :loading="isLoadingGeral" :rules="requiredField" />
        </v-col>
        <v-col >
            <v-autocomplete dense label="Tipo Guia" v-model="currentItem.tipoguia" :items="tipoguia" :item-text="tipoguiaText" item-value="id" outlined  :loading="isLoadingGeral" :rules="requiredField" />
        </v-col>
    </v-row>
    <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>
 
<script>
import api from '../../../../../http';
import UtilsFunc from '../../../../../service/utilsFunc';
import ConfirmButton from '../../../../ConfirmButton.vue';

export default {
  name: "EditarMaterial",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      requiredField: [e => (e !== null && e !== undefined && e !== '' ? true : 'Obrigatório')],
      isLoadingGeral: false,
      isLoadingOperadora: false,
      isLoadingProduto: false,
      tabela: [],
      despesa: [],
      tipoguia: [],
      operadora: [],
      produto: [],
      statusOptions: [
        { label: "Sim", value: true },
        { label: "Não", value: false }
      ],
       options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2,
      },
    })
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation)  return;

      
      const fields = {
        ...this.currentItem,
        operadora: this.currentItem.operadora,
        produto: this.currentItem.produto,
        tabela: this.currentItem.tabela,
        despesa: this.currentItem.despesa,
        tipoguia: this.currentItem.tipoguia,

      }
      // console.log('Enviado => ', fields)
      this.onSubmit(fields)
    },
    async getOperadoras() {
      try {
        const { data } = await api.get('operadoras/operadoras/');
        this.operadora = data
          .filter(operadora => operadora && operadora.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
          // console.log(this.operadora)
      } catch (error) {
        alert("Erro ao listar as Operadoras")
        console.log(error)
      }
    },
    async getMatMed() {
        this.isLoadingProduto = true
      try {
        const response = await api.get(`matmed/matmeds/`)
        this.produto = response.data.filter(item => item.ativo)
        console.log(this.produto)
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.isLoadingProduto = false
      }
    },
     async getTabela() {
        this.isLoadingGeral = true
      try {
        const { data } = await api.get('operadoras/tabelas/')
        this.tabela = data.filter(tabela => tabela.ativo)
        // console.log('tabelas --> ',this.tableValues)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getDespesa() {
         this.isLoadingGeral = true
      try {
        const { data } = await api.get('operadoras/despesas/')
        this.despesa = data.filter(despesa => despesa.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingGeral = false
      }
    },
    async getTipoGuia() {
         this.isLoadingGeral = true
      try {
        const { data } = await api.get('operadoras/tipoguia/')
        this.tipoguia = data.filter(tipoguia => tipoguia.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.isLoadingGeral = false
      } 
    },
    getPrecoPrecificacao() {
      if (!this.currentItem || !this.currentItem.produto) {
        // console.log("Nenhum produto selecionado");
        return "R$ 0,00";
      }

      const produtoSelecionado = this.produto.find(p => p.id === this.currentItem.produto);
      
      if (!produtoSelecionado) {
        // console.log("Produto não encontrado:", this.currentItem.produto);
        return "R$ 0,00";
      }

      // console.log("Produto Selecionado:", produtoSelecionado);

      const custo = parseFloat(produtoSelecionado.custo) || 0;
      const percentualCusto = parseFloat(produtoSelecionado.custo_empresa) || 0;
      
      // console.log("Custo:", custo, "Percentual Custo:", percentualCusto);

      const resultadoCusto = custo * (1 + percentualCusto / 100);
      
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(resultadoCusto);
    },
  },
  computed:{
    tabelaText() {
      return (item) => {
          return `${item.tabela} - ${item.nome}`;
      }
    },
    despesaText() {
      return (item) => {
          return `${item.despesa} - ${item.nome}`;
      }
    },
    tipoguiaText() {
      return (item) => {
          return `${item.tipoguia} - ${item.nome}`;
      }
    },
    precoPrecificacaoDinamico() {
      return this.getPrecoPrecificacao();
    }
    
  },
  mounted() {
    this.getOperadoras()
    this.getMatMed()
    this.getDespesa()
    this.getTipoGuia()
    this.getTabela()
    this.currentItem.ativo = Number(this.currentItem.ativo)
  },
  watch: {
    "currentItem.produto": {
      handler(newVal) {
        if (newVal) {
          this.getPrecoPrecificacao(); // Apenas chama a função, sem atribuir a precoPrecificacaoDinamico
        }
      },
      deep: true
    }
  }



}
</script>
