<template>
  <v-container fluid class="gestao-documentos">
    <v-card class="pa-4">
      <v-card-title>Importação de Arquivo Simpro</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row dense align="center">
            <!-- Campo para selecionar a versão -->
            <v-col cols="2">
              <v-text-field  
                v-model="versao"  
                label="Versão"  
                outlined  
                dense  
                type="number"  
                required  
              ></v-text-field>
            </v-col>

            <!-- Campo para selecionar o arquivo XML -->
            <v-col cols="6">
              <v-file-input  
                v-model="file"  
                label="Selecione o arquivo .XML da SIMPRO"  
                outlined  
                dense  
                accept=".xml"  
                required  
                @change="handleFileSelect"
              ></v-file-input>
            </v-col>

            <!-- Botão de Importação -->
            <v-col cols="2" class="d-flex align-center justify-end">
              <v-btn :loading="loading" color="primary" @click="uploadFile">
                Importar
              </v-btn>
            </v-col>
          </v-row>

          <!-- Mensagem de status -->
          <v-alert v-if="message" :type="alertType" class="mt-4">
            {{ message }}
          </v-alert>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- Tabela para mostrar os dados importados -->
    <v-card class="mt-4">
      <v-card-title class="d-flex justify-space-between align-center">
        <span>Lista Ativa de Produtos</span>
      </v-card-title>

      <v-card-text>
        <v-row align="center" class="mb-5">
          <!-- Campo de Pesquisa -->
          <v-col cols="8">
            <v-text-field  v-model="search"  label="Pesquisar por Descrição, Cod. Simpro ou Cod. TUSS"  outlined  dense  clearable  prepend-inner-icon="mdi-magnify"  ></v-text-field>
          </v-col>

          <!-- Botão de Atualizar -->
          <v-col cols="4" class="text-right">
            <v-btn color="blue" small @click="atualizar" :loading="loadingAtualizar">
              🔄 Atualizar Lista
            </v-btn>
          </v-col>
        </v-row>
        
        <!-- DataTable -->
        <v-data-table  
          :headers="headers"  
          :items="filteredItems"  
          dense  
          no-data-text="Nenhum dado importado ainda."  
          :loading="loadingAtualizar"
          :items-per-page="-1"
          height="500px"
          fixed-header  
          class="custom-table"
          virtual-scroll
        >
          <!-- Formatação da Data -->
          <template v-slot:[`item.data_importacao`]="{ item }">
            <span>{{ formatDate(item.data_importacao) }}</span>
          </template>
          <template v-slot:[`item.vigencia`]="{ item }">
            <span>{{ formatDate(item.vigencia) }}</span>
          </template>
          <!-- Formatação do Preço -->
          <template v-slot:[`item.preco_fab_unidade`]="{ item }">
            <span>{{ formatCurrency(item.preco_fab_unidade) }}</span>
          </template>
          <template v-slot:[`item.preco_fab_embalagem`]="{ item }">
            <span>{{ formatCurrency(item.preco_fab_embalagem) }}</span>
          </template>
           <template v-slot:[`item.cod_simpro`]="{ item }">
            <span>{{ formatTUSS(item.cod_simpro) }}</span>
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
              <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import '../../../../assets/css/styles.css'
import api from '../../../../http';
import moment from "moment"; // 📌 Importação do Moment.js para formatação de datas

export default {
    name: 'Simpro',
    components: {},
    data() {
      return {
        loadingAtualizar: false, // Indicador de carregamento ao atualizar
        versao: "",
        file: null,
        loading: false,
        message: "",
        alertType: "success",
        importedData: [], // Armazena os dados do banco
        search: "", // Campo de pesquisa
        headers: [
          { text: "ID", value: "id" },
          { text: "Versão", value: "versao" },
          { text: "Descrição", value: "descricao" },
          { text: "Fabrica", value: "fabrica" },
          { text: "Cod. Simpro", value: "cod_simpro" },
          { text: "Cod. TUSS", value: "cod_TUSS" },
          { text: "Preço Embalagem", value: "preco_fab_embalagem" },
          { text: "Preço UND", value: "preco_fab_unidade" },
          { text: "Qtd. Embalagem", value: "qtd_embalagem" },
          { text: "Anvisa", value: "registro_anvisa" },
          { text: "Data Vigência", value: "vigencia" },
          { text: "Data de Importação", value: "data_importacao" },
          { text: "Status", value: "ativo" },
        ],
      };
    },
    computed: {
      // 🔍 Filtra os dados conforme a pesquisa
      filteredItems() {
        if (!this.search) return this.importedData;
        const searchLower = this.search.toLowerCase();
        return this.importedData.filter(item => 
          item.descricao?.toLowerCase().includes(searchLower) ||
          String(item.cod_simpro).includes(this.search) ||
          String(item.cod_TUSS).includes(this.search)
        );
      }
    },
    methods: {
      handleFileSelect(file) {
        this.file = file;
      },

      // 📌 Método para formatar a data corretamente
      formatDate(dateString) {
        if (!dateString) return "-";
        return moment(dateString).format("DD/MM/YYYY");
      },
      // 📌 Formata valores para R$ (moeda brasileira)
      formatCurrency(value) {
        if (!value) return "R$ 0,00";
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(value);
      },
      formatTUSS(value) {
        if (!value) return ""; // Se for null ou undefined, retorna string vazia
        return String(value).padStart(10, "0"); // Garante que tenha 8 dígitos
      },
      async uploadFile() {
        if (!this.file || !this.versao) {
          this.message = "Por favor, selecione um arquivo e informe a versão.";
          this.alertType = "error";
          return;
        }

        this.loading = true;
        this.message = "";

        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("versao", this.versao);

        try {
          const response = await api.post("/matmed/simpro/importar/", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          this.message = response.data.message || "Importação realizada com sucesso!";
          this.alertType = "success";

          // Atualiza a tabela com os dados do banco após importar
          this.fetchImportedData();
        } catch (error) {
          this.message = error.response?.data?.error || "Erro ao importar o arquivo.";
          this.alertType = "error";
        } finally {
          this.versao = null,
          this.file = null,
          this.loading = false;
        }
      },
      async fetchImportedData() {
        this.loadingAtualizar = true;

        // **Efeito visual: Limpa os dados antes de carregar para mostrar a atualização**
        this.importedData = [];

        try {
          const response = await api.get("/matmed/simpro/");
          this.importedData = response.data;
        } catch (error) {
          this.message = "Erro ao carregar os dados importados.";
          this.alertType = "error";
        } finally {
          this.loadingAtualizar = false;
        }
      },
      atualizar() {
        this.fetchImportedData();
      },
    },
    mounted() {
      this.fetchImportedData();
    },
}
</script>

<style scoped>
.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.v-btn {
  font-size: 14px !important;
  height: 36px !important;
  padding: 0 15px !important;
}

.v-file-input, .v-text-field {
  font-size: 14px !important;
  height: 38px !important;
}

.text-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
